import React from 'react';
import { Link } from 'gatsby';

import logo from '../assets/images/oxford-logo.png';

export const HomeBanner = ({
  title,
  firstSubtitle,
  secondSubtitle,
  thirdSubtitle,
  backgroundImage,
}) => {
  return (
    <div
      className="homeBannerComponentContainer"
      style={{
        backgroundImage,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <img className="logo" src={logo} alt="Logo del instituto" />

      <div className="homeBannerText">
        <h1>{title}</h1>
        <h3>{firstSubtitle}</h3>
        <h3 className="regular">{secondSubtitle}</h3>
        <h3>{thirdSubtitle}</h3>
      </div>
      <button className="button">
        <Link to="/Inscripcion" className="viewMoreText">
          Inscribite Ya
        </Link>
      </button>
    </div>
  );
};
