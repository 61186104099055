import React, { Fragment } from 'react';

import '../assets/sass/main.scss';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import { Link } from 'gatsby';

import about from '../assets/images/about-oxford.jpg';
import onlineBanner from '../assets/images/home-banner-online.png';
import testimonial from '../assets/images/testim.jpg';
import backgroundCursos from '../assets/images/backgroundCursos.jpg';
import DrawerButton from '../components/DrawerButton';
import { HomeBanner } from '../components/HomeBanner';

const IndexPage = () => {
  return (
    <Fragment>
      <Layout>
        {/* <section id="banner">
          <div className="inner">
            <h2 className="bannerH">{config.heading}</h2>
            <p className="bannerP">{config.subHeading}</p>
            <div className="button viewMore">
              <Link to="/Viajes" className="viewMoreText">
                Ver más
              </Link>
            </div>
          </div>
        </section> */}
        <HomeBanner
          title="Enseñanza de Inglés"
          firstSubtitle="Modalidad: online y presencial"
          secondSubtitle="Clases grupales y personalizadas"
          thirdSubtitle="Plataforma virtual"
          backgroundImage={onlineBanner}
        />
        <section id="blueInfo">
          <div className="blueInfoContainer">
            <div id="a" className="blueBoxes">
              <Link className="blueButton" to="/Nosotros#jardinMaternal">
                Jardín Maternal
              </Link>
            </div>
            <div id="b" className="blueBoxes">
              <Link className="blueButton" to="/Examenes#intExams">
                Exámenes Internacionales
              </Link>
            </div>
            <div id="c" className="blueBoxes">
              <Link className="blueButton" to="/Cursos#enterprise">
                Servicios Empresariales
              </Link>
            </div>
            <div id="d" className="campusBox">
              <a className="blueButton" href="https://campusnube.com.ar/login">
                Ingreso a Plataforma Educativa
              </a>
            </div>
          </div>
        </section>

        <section id="wrapper">
          <section id="somos">
            <div className="somosContainer">
              <Link className="imgCont" to="/Nosotros">
                <img src={about} alt="" />
              </Link>
              <div className="textCont">
                <Link to="/Nosotros">
                  <h2 className="title">Sobre Oxford House</h2>
                </Link>
                <p className="cont">
                  Bienvenidos a Oxford House, somos una academia líder en la
                  enseñanza de inglés y especialistas en la enseñanza para niños
                  desde los dos años. Ofrecemos también cursos muy dinámicos,
                  interactivos y de una excelente calidad para adolescentes y
                  adultos. Además de clases personalizadas en grupos reducidos.
                  <br></br>
                  <br></br>
                  Nuestro objetivo es asegurarnos de que todos nuestros
                  estudiantes mejoren su inglés y otros idiomas y alcancen sus
                  metas de aprendizaje. Proporcionamos un plan de estudios
                  personalizado y brindamos un apoyo y atención individualiza da
                  durante todo el curso para garantizar el progreso rápidamente
                  y logren sus objetivos de aprendizaje.
                </p>
              </div>
              <div className="botonera">
                <Link to="/Nosotros" className="boton">
                  Ver más
                </Link>
                <Link to="/Inscripcion" className="botonInsc">
                  Inscribite ahora!
                </Link>
              </div>
            </div>
          </section>

          <section
            id="cursos"
            style={{
              backgroundImage: `url("${backgroundCursos}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            className="wrapper spotlight style1 cursosWrapper"
          >
            <div className="inner testimonial">
              <h2 className="major">Nuestros programas</h2>

              <h3>Ofrecemos variedad de cursos:</h3>

              <div id="cursosCont">
                <div className="flex">
                  <div className="cursito">
                    <Link to="/Cursos#kids23">
                      <div className="cursitoImage"></div>
                      <h4>
                        Niños <br></br>2 - 3 <br></br>años
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#kids46">
                      <div className="cursitoImage"></div>
                      <h4>
                        Niños <br></br>4 - 6 <br></br>años
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#kids712">
                      <div className="cursitoImage"></div>
                      <h4>
                        Niños <br></br>7 - 12 <br></br>años
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#teens1317">
                      <div className="cursitoImage"></div>
                      <h4>
                        Adolescentes <br></br>13 - 17<br></br> años
                      </h4>
                    </Link>
                  </div>
                </div>

                <div className="flex">
                  <div className="cursito">
                    <Link to="/Cursos#adults">
                      <div className="cursitoImage2"></div>
                      <h4>
                        Programa <br></br>Adultos
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#intensive">
                      <div className="cursitoImage2"></div>
                      <h4>
                        Cursos<br></br> intensivos
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#crashCourses">
                      <div className="cursitoImage3"></div>
                      <h4>
                        Crash<br></br> Courses
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#conversation">
                      <div className="cursitoImage2"></div>
                      <h4>
                        Clases <br></br>de <br></br>conversación
                      </h4>
                    </Link>
                  </div>
                </div>

                <div className="flex">
                  <div className="cursito">
                    <Link to="/Cursos#specific">
                      <div className="cursitoImage2"></div>
                      <h4>
                        Inglés <br></br>para <br></br>propósitos <br></br>
                        específicos
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#clil">
                      <div className="cursitoImage3"></div>
                      <h4>
                        English <br></br>with <br></br>CLIL
                      </h4>
                    </Link>
                  </div>

                  <div className="cursito">
                    <Link to="/Cursos#enterprise">
                      <div className="cursitoImage3"></div>
                      <h4>
                        Inglés <br></br>para <br></br> empresas
                      </h4>
                    </Link>
                  </div>
                  <div className="cursito">
                    <Link to="/Cursos#otherLanguages">
                      <div className="cursitoImage2"></div>
                      <h4>
                        Otros <br></br>idiomas
                      </h4>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="four" className="wrapper alt cuatro style1">
            <div className="inner2">
              <h2 className="majore">Examenes internacionales</h2>
              <p className="cont">
                Nuestros alumnos podrán rendir exámenes internacionales de
                TRINITY COLLEGE <br />
                LONDON y PATH International examinations.
              </p>
              <section className="features">
                <article className="articulo">
                  <Link to="/Examenes#gese" className="image">
                    <div className="courseName">
                      <h5>GESE</h5>
                    </div>
                  </Link>
                  <div className="articuloContent">
                    <h3 className="major">
                      Graded Examinations In Spoken English
                    </h3>
                    <p>
                      Son exámenes individuales especialmente diseñados para
                      desarrollar el habla y la comprensión oral en inglés. Son
                      otro recurso ofrecido por Trinity para complementar el
                      desarrollo de las habilidades en inglés de cada candidato,
                      así como un importante apoyo para alcanzar el nivel de
                      comunicación necesario para su uso en la vida real,
                      empleos, estudios y más.
                    </p>
                    <Link to="/Examenes#gese" className="button">
                      Ver más
                    </Link>
                  </div>
                </article>
                <article className="articulo">
                  <Link to="/Examenes#ise" className="image">
                    <div className="courseName">
                      <h5>ISE</h5>
                    </div>
                  </Link>
                  <div className="articuloContent">
                    <h3 className="major">Integrated Skills In English</h3>
                    <p>
                      Los exámenes ISE (Integrated Skills in English) del
                      Trinity College London evalúan las cuatro destrezas
                      lingüísticas – hablar, escribir, escuchar y leer –
                      conforme con el Marco Común Europeo de Referencia del
                      Consejo de Europa (MCER). Estos exámenes están
                      especialmente enfocados al uso práctico del inglés, y
                      sobre todo en la producción oral y escrita.
                    </p>
                    <Link to="/Examenes#ise" className="button">
                      Ver más
                    </Link>
                  </div>
                </article>
                <article className="articulo">
                  <Link to="/Examenes#path" className="image">
                    <div className="courseName">
                      <h5>PATH</h5>
                    </div>
                  </Link>
                  <div className="articuloContent">
                    <h3 className="major">
                      Exámenes Para Niños <br></br>
                      <br></br>
                    </h3>
                    <p>
                      Estos exámenes son diseñados para niños a partir de los 4
                      años. Los examinadores de estos niveles están capacitados
                      para que la experiencia sea fructífera y motivar a los
                      chicos a continuar el proceso de aprendizaje en el idioma.
                    </p>
                    <Link to="/Examenes#path" className="button">
                      ver más
                    </Link>
                  </div>
                </article>
              </section>
            </div>
          </section>

          <section id="one" className="wrapper spotlight style1 ">
            <div className="inner testimonial">
              <div href="/#" className="image center">
                <img src={testimonial} className="testiPic" alt="" />
              </div>

              <div className="content">
                <h2 className="major">Testimonial</h2>
                <p className="testimonio">
                  “Comencé a ir a Oxford House en el año 2011, y encontré un
                  cálido ambiente. Con el pasar de los años, encontré una gran
                  evolución, tanto en el instituto, como así también en mi nivel
                  de inglés. La preparación para los exámenes Trinity (GESE) fue
                  excelente, con todos los profesores involucrados en mi
                  aprendizaje. Oxford House es una propuesta innovadora, que no
                  sólo se enfoca en lo gramático si no que también en pequeñas
                  cosas que podríamos utilizar en futuros viajes, o incluso a
                  nivel laboral, además de generar una increíble relación
                  profesor-alumno. ”
                </p>
                <p className="nombre">
                  Guillermina Sap Tomassi - GESE level 10
                </p>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </Layout>
      <DrawerButton />
    </Fragment>
  );
};

export default IndexPage;
